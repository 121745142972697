<template>
  <b-container
    v-if="renderPage"
    fluid
  >
    <b-tabs
      content-class="mt-2"
      justified
      align="center"
    >
      <b-tab
        title="Usuários"
        lazy
      >
        <all-users />
      </b-tab>
      <b-tab
        title="Grupos"
        lazy
      >
        <all-groups />
      </b-tab>
    </b-tabs>
  </b-container>
</template>
<script>
import {
  BContainer,
  BTab,
  BTabs,
} from 'bootstrap-vue'

import AllGroups from './AllGroups.vue'
import AllUsers from './AllUsers.vue'

export default {
  components: {
    AllGroups,
    AllUsers,
    BTabs,
    BTab,
    BContainer,
  },
  data() {
    return ({
      renderPage: true,
    })
  },
  beforeRouteLeave(to, from, next) {
    this.renderPage = false
    next()
  },
}
</script>

  <style lang="scss" scoped>
  @import "@core/scss/vue/pages/user.scss";
  </style>

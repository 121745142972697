<template>
  <b-container>
    <b-card title="Buscar Grupo">
      <b-col
        cols="12"
        md="12"
        class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
      >
        <b-form-input
          v-model="filter"
          placeholder="Informe o Nome do Funcionário"
          type="text"
          class="d-inline-block mr-1"
        />

        <b-button
          variant="primary"
          :to="{ name: 'create-group' }"
        >
          <span class="text-nowrap">Novo Grupo</span>
        </b-button>
      </b-col>
    </b-card>
    <b-card>
      <b-table
        :per-page="perPage"
        :current-page="currentPage"
        :fields="table_users_fields"
        :items="users"
        :filter="filter"
        striped
        hover
        responsive
        :busy="isLoading"
        class="text-center"
        :tbody-tr-class="rowRevoked"
        @filtered="onFiltered"
      >
        <template #cell(cnpj)="data">
          {{ data.item.cnpj | tax_id }}
        </template>

        <template #cell(cpf)="data">
          {{ data.item.cpf | tax_id }}
        </template>

        <template #cell(createdAt)="data">
          {{ data.item.createdAt | date }}
        </template>
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              :to="{ name: 'edit-group', params: { id: data.item.id } }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Editar</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <b-pagination
        v-if="!isLoading"
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="center"
        size="sm"
        class="my-0"
      />
    </b-card>
  </b-container>
</template>
<script>
import {
  BContainer,
  BTable,
  BButton,
  BPagination,
  BCard,
  BCol,
  BFormInput,
  BDropdown,
  BDropdownItem,
  BSpinner,
} from 'bootstrap-vue'
import GroupsService from '@/service/credit_platform/groups'

export default {
  components: {
    BContainer,
    BCol,
    BTable,
    BButton,
    BPagination,
    BCard,
    BFormInput,
    BDropdown,
    BDropdownItem,
    BSpinner,
  },
  data() {
    return {
      filter: null,
      perPage: 8,
      totalRows: null,
      currentPage: 1,
      isLoading: false,
      detailsClassFn: 'is-revoked',
      table_users_fields: [
        { key: 'name', label: 'Nome' },
        { key: 'companyName', label: 'Nome do Grupo' },
        { key: 'cpf', label: 'CPF' },
        { key: 'cnpj', label: 'cnpj' },
        { key: 'createdAt', label: 'Criado em' },
        { key: 'actions', label: 'Ações' },
      ],
      users: null,
    }
  },
  async mounted() {
    await this.listAllUsers()
  },
  methods: {

    responseModal(success, user) {
      if (success) {
        this.$swal({
          icon: 'success',
          html: `<span>O usuário ${user} foi revogado com sucesso </span>`,
        })
      } else {
        this.$swal({
          icon: 'error',
          html: `<span> Não foi possível revogar o usuário ${user} </span>`,
        })
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    async listAllUsers() {
      this.isLoading = true

      const { data: { data } } = await GroupsService.getAllGroups()

      this.totalRows = data.length

      const usersList = data.map(group => ({
        id: group.id,
        cnpj: group.cnpj,
        cpf: group.cpf,
        companyName: group.company_name,
        fullAccess: group.full_access,
        createdAt: group.created_at,
        isRevoked: group.isRevoked,
        name: group.name,
      }))

      this.users = usersList.sort((a, b) => (a.name.localeCompare(b.name)))
      this.isLoading = false
    },
  },
}
</script>

  <style lang="scss">
    .is-revoked {
      background-color: rgba(0, 0, 0 , .09) !important;
      &:hover{
        background-color: rgba(0, 0, 0 , .11) !important;
      }
    }
  </style>
